import { createVNode as _createVNode, unref as _unref, vShow as _vShow, createElementVNode as _createElementVNode, withDirectives as _withDirectives, Transition as _Transition, withCtx as _withCtx, resolveComponent as _resolveComponent, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-29db148e"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "w_1200 warp" }
const _hoisted_2 = { class: "show-table-container" }
const _hoisted_3 = {
  key: 0,
  class: "pagination"
}

import ShowNewOffer from "./showOferr.vue"; // 引入
import SearchGoods from "../SearchGoods/index.vue"; // 搜索
import {
  computed,
  nextTick,
  onActivated,
  onMounted,
  reactive,
  ref,
  watch,
  watchEffect,
  onDeactivated,
  onUnmounted,
} from "vue";
import navViewsVue from "./navViews.vue";
import { onBeforeRouteLeave, useRoute, useRouter } from "vue-router";
import { usePriceStore } from "@/pinia/api/useDetailOffers.js";
import { useClassIfy } from "@/pinia/api/classIfy.js"; // pinia
import { storeToRefs } from "pinia";
import { ElMessage } from "element-plus";

export default {
  setup(__props) {

const storeClassIfy = useClassIfy(); //分类的 pinias
storeClassIfy.initClassIfy(); // 初始化 分类列表
const { nameArr } = storeToRefs(storeClassIfy);
const store = usePriceStore();
const currentPage1 = ref(1); // 当前页
const route = useRoute();
const router = useRouter();
const searchWords = ref("");
const action = ref("all"); //操作数据的行为为all
const key = ref(""); // 如果是搜索页，当前的搜索关键词
const { quoteList } = storeToRefs(store); // 把数据取出来
store.initOfferData();

// 模糊搜索的方法
const searchHnadler = async (params) => {
  action.value = "search";
  store.searchQuoteList(params);
};

// 点击表格内部的品名
const click_name = () => {
  searchHnadler();
};

// 点击品名的时候触发
const clickHandler = (row) => {
  storeClassIfy.params = {
    name: row.type_title,
  };
  store.params.name = row.type_title;
  store.params.spec = "";
  store.params.texture = "";
  PsetAll();
  storeClassIfy.searchClassify();
  searchHnadler();
};
// 点击规格触发
const clickOthrer1 = (row) => {
  storeClassIfy.params.spec = row;
  storeClassIfy.searchClassify();
  store.params.spec = row;
  searchHnadler();
};

// 点击参数触发
const clickOthrer2 = (row) => {
  storeClassIfy.params.texture = row;
  storeClassIfy.searchClassify();
  store.params.texture = row;
  searchHnadler();
};

//点击全部的时候触发
const clickAll = (flag) => {
  switch (flag) {
    case "品名":
      storeClassIfy.params = {};
      store.params.name = "";
      store.params.spec = "";
      store.params.texture = "";
      searchWords.value = "";
      storeClassIfy.curTbasId = "all";
      PsetAll();
      searchHnadler();
      storeClassIfy.getNameArr();
      break;
    case "规格":
      storeClassIfy.params.spec = "";
      store.params.spec = "";
      searchHnadler();
      if (storeClassIfy.params.name) {
        storeClassIfy.searchClassify();
      } else {
        storeClassIfy.getNameArr();
      }
      break;
    case "材质":
      store.params.texture = "";
      storeClassIfy.params.texture = "";
      searchHnadler();
      if (storeClassIfy.params.name) {
        storeClassIfy.searchClassify();
      } else {
        storeClassIfy.getNameArr();
      }
      break;
  }
};

const isShowNav = ref(true);

const searchList = [
  {
    title: "品名",
    children: nameArr,
  },
];

// 城市改变的时候
const changCity = (id) => {
  store.params.regionId = id;
  searchHnadler();
};

// 当前页改变时触发
const handleCurrentChange = (val) => {
  searchHnadler({ page: val });
  currentPage1.value = val;
};

// 选择主分类的时候 进行查询
const changeType = (id) => {
  store.params.type = id;
  store.params.name = ""; // 清空品名
  store.params.spec = ""; // 清空 材质
  store.params.texture = ""; // 清空规格
  store.params.user_id = ""; // 清空搜索人
  nextTick(() => {
    searchHnadler(); // 调用方法直接执行进行搜索
    breadcrumbStore.setMatched("最新报价"); //  // 设置面包屑 只有点击拼命的时候会触发
  });
};

// 用户点击了收起
const packUp = () => {
  isShowNav.value = !isShowNav.value;
};

//点击了搜索
const search = (keyWords) => {
  // 在搜索页 直接搜索就可以了
  store.params.info = keyWords.value;
  searchHnadler();
};

//当用户输入了金额并且点击了确定时
const changePrice = (priceArr) => {
  //通过价格进行查询
  searchHnadler();
};

// 用户点击more后触发的时间
const moreHandler = (row) => {
  store.clickMore({ company_id: row.id, limit: 4, target: "offer" });
};

// 用户点击收起时触发
const puckHandler = (row) => {
  store.clickPuck(row, "offer");
};

// 子组件dom
const specDom = ref(null);
const textureDom = ref(null);
const nameDom = ref(null);

// 材质和规格 的setAll
const PsetAll = () => {
  specDom.value.setAll();
  textureDom.value.setAll();
};

// 当点击其他选项的时候进行重置到全部，同时把保存的查询参数也要干掉
const setAllHandler = (_) => {
  PsetAll();
  nameDom.value.setAll();
  store.clearParams();
};

return (_ctx, _cache) => {
  const _component_el_pagination = _resolveComponent("el-pagination")

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createVNode(SearchGoods, {
      onPackUp: packUp,
      onSearch: search,
      onPrice: changePrice,
      isShowNav: isShowNav.value,
      onSetAll: setAllHandler,
      onChangeCity: changCity,
      onChangeType: changeType
    }, null, 8, ["isShowNav"]),
    _createVNode(_Transition, { name: "fade" }, {
      default: _withCtx(() => [
        _withDirectives(_createElementVNode("div", null, [
          _createVNode(navViewsVue, {
            searchList: { title: '品名', children: _unref(storeClassIfy).nameArr },
            onClickName: clickHandler,
            onAll: clickAll,
            ref_key: "nameDom",
            ref: nameDom
          }, null, 8, ["searchList"]),
          _createVNode(navViewsVue, {
            searchList: { title: '规格', children: _unref(storeClassIfy).specList },
            onClickOthrer: clickOthrer1,
            onAll: clickAll,
            ref_key: "specDom",
            ref: specDom
          }, null, 8, ["searchList"]),
          _createVNode(navViewsVue, {
            searchList: { title: '材质', children: _unref(storeClassIfy).textureList },
            onClickOthrer: clickOthrer2,
            onAll: clickAll,
            ref_key: "textureDom",
            ref: textureDom
          }, null, 8, ["searchList"])
        ], 512), [
          [_vShow, isShowNav.value]
        ])
      ]),
      _: 1
    }),
    _createElementVNode("div", _hoisted_2, [
      _createVNode(ShowNewOffer, {
        search: searchWords.value,
        newOfferTable: _unref(quoteList),
        onMoreHandler: moreHandler,
        onPuck: puckHandler,
        onCLICK_NAME: click_name
      }, null, 8, ["search", "newOfferTable"]),
      (_unref(store).total > 20)
        ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
            _createVNode(_component_el_pagination, {
              currentPage: currentPage1.value,
              "onUpdate:currentPage": _cache[0] || (_cache[0] = $event => ((currentPage1).value = $event)),
              "page-size": 20,
              size: "small",
              background: "",
              layout: " prev, pager,next,total,jumper",
              total: _unref(store).total,
              onCurrentChange: handleCurrentChange
            }, null, 8, ["currentPage", "total"])
          ]))
        : _createCommentVNode("", true)
    ])
  ]))
}
}

}